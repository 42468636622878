import { Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import { colors, Logo, Ic_facebook, Ic_instagram, Ic_youtube } from "values";

// Styles
export const useStyles = makeStyles((theme) => ({
  title: {
    color: "#fff",
    fontSize: 16,
    fontWeight: 600,
    margin: 0,
    paddingBottom: 10,
  },
  info: { color: "#fff", fontSize: 14, margin: 0 },
  container: {
    background: colors.purple,
    padding: "30px 0",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div>
      <div className={classes.container}>
        <Container maxWidth="xl">
          <div style={{ textAlign: "center", paddingBottom: 30 }}>
            <img
              alt=""
              src={Ic_facebook}
              style={{ margin: 10, width: 40, cursor: "pointer" }}
              onClick={() => window.open("https://www.facebook.com/upeeoficial")}
            />
            <img
              alt=""
              src={Ic_instagram}
              style={{ margin: 10, width: 40, cursor: "pointer" }}
              onClick={() => window.open("https://www.instagram.com/upeeoficial/")}
            />
            <img
              alt=""
              src={Ic_youtube}
              style={{ margin: 10, width: 40, cursor: "pointer" }}
              onClick={() => window.open("https://www.youtube.com/channel/UCD0yWOnYtNgFkmlTGulG2mg")}
            />
          </div>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", justifyContent: "center", marginBottom: 50 }}>
                <div>
                  <p style={{ margin: 0, fontSize: 18, color: "#fff", fontFamily: "m_regular", marginBottom: 5 }}>
                    {"Contáctanos"}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: 18,
                      color: "#fff",
                      fontFamily: "m_regular",
                      fontWeight: 600,
                      marginBottom: 5,
                    }}
                  >
                    {"hola@upee.com.mx"}
                  </p>
                  <p style={{ margin: 0, fontSize: 18, color: "#fff", fontFamily: "m_regular" }}>
                    <a style={{ fontFamily: "m_semi_bold" }}>{"(+52) 33 2447 7990"}</a>
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div style={{ display: "flex", justifyContent: "center", marginBottom: 50 }}>
                <div>
                  <p className={classes.title} style={{ fontFamily: "m_semi_bold" }}>
                    {"SERVICIO AL CLIENTE"}
                  </p>
                  <p
                    onClick={() => history.push("/politicas-de-privacidad")}
                    className={classes.info}
                    style={{ fontFamily: "m_regular", cursor: "pointer", userSelect: "none", marginBottom: 5 }}
                  >
                    {"Políticas de Privacidad"}
                  </p>
                  <p
                    onClick={() => history.push("/terminos-y-condiciones")}
                    className={classes.info}
                    style={{ fontFamily: "m_regular", cursor: "pointer", userSelect: "none" }}
                  >
                    {"Términos y condiciones"}
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div style={{ textAlign: "center", padding: 50 }}>
        <Container>
          <img alt="upee" src={Logo} style={{ maxWidth: 150, marginBottom: 50 }} />
          <p style={{ fontSize: 10, margin: 0, fontFamily: "m_regular" }}>
            {
              "©2021 Notend Technology Group S.A de C.V. Todos los derechos reservados. Las distintas marcas comerciales pertenecen a sus respectivos propietarios."
            }
          </p>
        </Container>
      </div>
      <label style={{ fontSize: 8 }}>{"v 1.2.3"}</label>
    </div>
  );
};

export default Footer;
