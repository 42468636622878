import { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { urlVideo, colors } from "values";
import { getUrlVideo } from "utils";

// Styles
export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 90,
    margin: 0,
    color: colors.red,
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: 60,
      marginBottom: 20,
    },
  },
  video: {
    width: "100%",
    height: 700,
    [theme.breakpoints.down("md")]: {
      height: 500,
    },
    [theme.breakpoints.down("sm")]: {
      height: 450,
    },
    [theme.breakpoints.down("xs")]: {
      height: 250,
    },
  },
}));

const Banner = () => {
  const classes = useStyles();

  const [codeVideo, setCodeVideo] = useState("");

  useEffect(() => {
    if (urlVideo) {
      const url = getUrlVideo(urlVideo);
      setCodeVideo(url);
    }
  }, [urlVideo]);
  return (
    <div style={{ paddingTop: 70 }}>
      <Container>
        <div style={{ textAlign: "center" }}>
          <h2 className={classes.title} style={{ fontFamily: "walfords" }}>
            {"Conoce mas"}
          </h2>
          <p style={{ color: colors.purple, marginBottom: 30, marginTop: -15, fontSize: 18, fontFamily: "m_medium" }}>
            {
              "El dispositivo más práctico y funcional que te permitirá ir al baño de pie, creado por mujeres Mexicanas."
            }
          </p>
          <p style={{ color: colors.purple, marginBottom: 70, marginTop: -15, fontSize: 18, fontFamily: "m_medium" }}>
            {
              "Las mujeres estamos  revolucionando y  creciendo más que nunca, es por ello que upee es la mejor herramienta que te podrás encontrar que te brindará libertad, independencia y sobre todo seguridad. Sin limitaciones de lugares sucios, en mal estado o incluso al aire libre, ya que no requieres bajarte el pantalón completo. Atrévete a usarlo y se parte de este gran cambio!"
            }
          </p>

          {/* <div className={classes.video} style={{ background: colors.silver }}>
            <iframe
              className={classes.video}
              src={`${codeVideo}?fs=1`}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div> */}

          <p style={{ color: colors.purple, fontSize: 18, marginTop: 70, fontFamily: "m_medium" }}>
            {"Upee es el  dispositivo urinario más práctico e higiénico que ayuda a las mujeres a orinar de pie."}
          </p>
          <p style={{ color: colors.purple, fontSize: 18, fontFamily: "m_medium" }}>
            {
              "Gracias a que esta hecho con polietileno de alta densidad, el cual no permite adherencias, malos olores ni la reproducción de bacterias, también cuenta con el registro ante el IMPI ( Instituto Mexicano Propiedad Intelectual) por su diseño MX4079B.  Asegurando ser el único en el mercado con borde de seguridad que te permite no tener contacto con el dispositivo mientras realizas la acción, así mismo evita accidentes o derrames, deformaciones y te brinda un mayor soporte para que te sientas segura al usarlo."
            }
          </p>
          <p style={{ color: colors.purple, fontSize: 18, fontFamily: "m_medium" }}>
            {
              "Su usabilidad también permite ser usado  sentada, en caso de estar en recuperación de cirugía, silla de ruedas y alguna situación que te lo requiera, con la =nalidad de que te sea más práctico y sencillo. En el caso de silla de ruedas NO requieres moverte de tu silla, consulta nuestro instructivopara conocer más detalles."
            }
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Banner;
