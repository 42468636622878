import { Fragment } from "react";

// Components
import TravelAndUpee from "../global/TravelAndUpee";
import WhatIsUpee from "./WhatIsUpee";
import UpeeTakesCareOfYou from "../global/UpeeTakesCareOfYou";
import ParallaxBanner from "./ParallaxBanner";
import NearYou from "./NearYou";

import Info from "../global/Info";
import Top from "../global/Top";
import Banner from "./Banner";
import Video from "components/global/Video";
import DoYouWantToBeADistributor from "./DoYouWantToBeADistributor";
import Subscribe from "../global/Subscribe";
import JustDoIt from "./JustDoIt";

const Home = () => {
  return (
    <Fragment>
      <Banner />
      <Info />
      <JustDoIt />
      {/* <Video /> */}
      <Top />
      <DoYouWantToBeADistributor />

      {/* <WhatIsUpee /> */}
      {/* <ParallaxBanner /> */}

      {/* <UpeeTakesCareOfYou /> */}
      {/* <NearYou /> */}

      <TravelAndUpee />
      <Subscribe />
    </Fragment>
  );
};

export default Home;
